<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="indigo"
      icon="mdi-gavel"
      title="Listado Régimen Económico Matrimonial"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="rems"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="8"
                        >
                          <v-text-field
                            v-model="editedItem.nombre"
                            :rules="[v => !!v || 'El nombre es requerido']"
                            label="Nombre"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <dashboard-audit-trail
              :visible="dialogAudit"
              :logs="logs"
              :titulo="titleLog"
              @close="dialogAudit = false"
            />
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            small
            color="error"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="info"
            @click.stop="showLogs(item)"
          >
            mdi-history
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import MaestrosRemApi from '@/services/api/MaestrosRem'

  export default {
    name: 'MaestrosRem',

    components: {
      DashboardAuditTrail: () => import('@/views/dashboard/component/AuditTrail'),
    },

    data () {
      return {
        dialogAudit: false,
        titleLog: '',
        logs: {},
        valid: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        rems: [],
        editedIndex: -1,
        lazy: false,
        headers: [
          {
            text: 'Nombre',
            align: 'left',
            sortable: false,
            value: 'nombre',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          nombre: '',
        },
        defaultItem: {
          id: '',
          nombre: '',
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo régimen' : 'Modificar régimen'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchRem () {
        MaestrosRemApi.getAllRem()
          .then(rem => {
            this.rems = rem
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchRem()
      },

      editItem (item) {
        this.editedIndex = this.rems.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.rems.indexOf(item)
        if (confirm('Realmente desea eliminar este registro?')) {
          MaestrosRemApi.deleteRem(item.id)
            .then(response => {
              this.rems.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            const updRem = this.editedItem
            const indexItem = this.editedIndex
            MaestrosRemApi.updateRem(this.editedItem.id, this.editedItem)
              .then(response => {
                Object.assign(this.rems[indexItem], updRem)
                this.text = 'Registro modificado correctamente'
                this.snackbar = true
                this.colorSnak = 'success'
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
                this.editedIndex = -1
              })
          } else {
            const addRem = this.editedItem
            MaestrosRemApi.addRem(this.editedItem)
              .then(response => {
                addRem.id = response.id
                this.rems.push(addRem)
                this.text = 'Registro creado correctamente'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
          this.close()
        }
      },

      showLogs (item) {
        this.titleLog = item.nombre
        MaestrosRemApi.getLogsRem(item.id)
          .then(logs => {
            this.logs = logs
            this.dialogAudit = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'Error al cargar los datos de auditoría.'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
    },
  }
</script>
