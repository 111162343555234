import axios from 'axios'

export function getAllRem () {
  return axios.get('/maestros/rem')
  .then(response => {
    return response.data
  })
}

export function updateRem (id, rem) {
  return axios.put('/maestros/rem/' + id, rem)
  .then(response => {
      return response.data
    })
}

export function addRem (rem) {
  return axios.post('/maestros/rem', rem)
  .then(response => {
    return response.data
  })
}

export function deleteRem (id) {
  return axios.delete('/maestros/rem/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneRem (id) {
    return axios.get('/maestros/rem/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsRem (id) {
  return axios.get('/maestros/rem/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllRem,
  updateRem,
  addRem,
  deleteRem,
  getOneRem,
  getLogsRem,
}
